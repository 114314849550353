    form{
        display: flex;
        flex-direction: column;
    }
    
    label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
    }
    input[type="text"], input[type="email"], textarea {
        width: 500px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
    }
    select {
        width: 500px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
    }
    input[type="submit"] {
        background-color: #4CAF50;
        color: white;
        padding: 12px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin: 40px;
    }
    input[type="submit"]:hover {
        background-color: #45a049;
}

.contact-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

iframe{
    border-radius: 15px;
    margin: 20px;
}

.form-btn{
    border: none;
    background-color: #b52974;
    color: white;
    
    
    border-radius: 5px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: larger;
    margin-top: 10px;
}