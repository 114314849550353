.calc-btn{
    border: none;
    background-color: #b52974;
    color: white;
    width: 100px;
    height: 30px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: larger;
    margin-top: 10px;
}

input{
    border-radius: 5px;
    margin-top: 10px;
    height: 30px;
    font-size: 30px;
    width: 40px;

}