body{
  background-color: #fed9a8;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

nav{
  display: flex;
  height: 30px;
  padding: 20px;
  background-color: antiquewhite;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
}

.lang-btn{
  margin-left: auto;
  margin-right: 50px;
}

.apply-btn{
  border: none;
  background-color: blue;
  color: white;
  width: 156px;
  height: 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger;
}

.contact-btn{
  border: none;
  background-color: blue;
  color: white;
  width: 50px;
  height: 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger;
}





.footer-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;
  color: white;
}

.apply-now{
  position: fixed;
  z-index: 1000;
  bottom: 110px;
  right: 30px;
}

.call-now{
  position: fixed;
  z-index: 1000;
  bottom: 90px;
  left: 30px;
}

.email{
  position: fixed;
  z-index: 1000;
  bottom: 90px;
  left: 30px;
}

.whatsaap{
  position: fixed;
  z-index: 1000;
  bottom: 160px;
  left: 30px;
}

.slideshow{
  height: 600px;
  background-color: #fed9a8;
  width: 100%;
}

.start-now-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
  margin-top: 80px;
  width: 100% auto;
  margin: 20px;
  margin-bottom: 80px;

}

.title-box{
  position: relative;
  z-index: 100;
  color: white;
  width: 194px;
  height: 43px;
  padding: 30px;
  background-color: #b52974 ;
  border-radius: 15px;
  box-shadow: deepskyblue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  left: 5%;
  bottom: -81px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
}

.box{
  height: 300px auto;
  background-color: #fefcdf ;
  z-index: -1;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 40px;
  margin-top: 100px;
  margin: 10px;
  min-width: 100px ;
  width: 600px auto;
}

.content{
  margin-top: 35px;
}

.opportunity-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.opportunity-content{
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}



.opportunity-item{
  display: flex;
  align-items: center;
  
  background-color: azure;
  width: 250px;
  height: 50px;
  margin: 10px;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
}

.opportunity-img{
  width: 50px;
  margin: 10px;
}

.opportinity-title-box{
  position: relative;
  z-index: 100;
  width: 170px;
  height: 43px;
  padding: 42px;
  color: white;
  background-color: #b52974 ;
  border-radius: 15px;
  box-shadow: deepskyblue;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -10%;
  bottom: -55px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
}

#logo{
  height: 70px;
  width: 70px;
  margin-right: 10px;
}

.name-head{
  width: 300px;
  display: flex;
  flex-direction: column ;
}

.name-head h1{
  width: 300px;
  display: flex;
  flex-direction: column ;
  margin-bottom: -10px;
}

.logo{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1200px;

}

.enjoy-container{
  width: 100%;
  display: flex;
  align-items: center;

  justify-content: center;
}

.enjoy-item{
  display: flex;
  justify-content: center;
  background-color: azure;
  width: 230px;
  margin: 30px;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
  height: 350px;
}

.enjoy-box{
  position: relative;
  height: 530px;
  background-color: #fefcdf ;
  z-index: -1;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 40px;
  margin: 10px;
  width: 900px ;
}

.contact-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.enjoy-title-box{
  position: relative;
  z-index: 100;
  width: 204px;
  color: white;
  height: 61px;
  padding: 42px;
  background-color: #b52974;
  border-radius: 15px;
  box-shadow: deepskyblue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  left: -11%;
  bottom: -81px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
}


.video-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 700px;
  margin-bottom: 50px;
}



.video-title-box{
  position: relative;
  z-index: 100;
  width: 170px;
  height: 70px;
  padding: 20px;
  background-color: #b52974;
  border-radius: 15px;
  box-shadow: deepskyblue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  left: 27%;
  bottom: -100px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
  color: white;
}

.video-box{
  position: relative;
  height: 300px;
  background-color: #fefcdf ;
  z-index: -1;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 40px;
  margin: 10px;
  left: -5%;
  width: 900px;
  
}

.video-supreme-box{
  width: 100%;
  
  z-index: 100;
}

.video-clips{
  display: flex;
  scroll-behavior: smooth;
  align-items: center;
  overflow: scroll;
  width: 900px;
  justify-content: center;
}

.videos{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: antiquewhite;
}


.faq-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.startup-steps-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.question{
  display: flex;
  flex-direction: column;
  background-color: azure;
  width: 600px;
  margin: 10px;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
}

.item{
  display: flex;
  align-items: center;
}

.answer-btn{
  margin-left: auto;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 10px;
  height: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;
}

.lang-btn{
  border: none;
  background-color: #b52974;
  color: white;
  width: 50px;
  height: 30px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
}


.answer{
  border-top: 1px solid rgba(0, 0, 0, 0.241);
}

.footer-container{
  display: flex;
  align-items: center;
}

.links{
  margin-left: auto;
  margin-right: 120px;
  padding: 40px;
}

.videos{
  z-index: 1000;
}

h3{

  margin-bottom: -15px;
}

a{
  margin: 10px;
  color: white;
}

.app{
  display: grid;
  place-items: center;
}

/*-----------------------------------------Slideshow -----------------------------------------------css*/

.slideshow{
  height: 600px;
}

.carousel-container {
  position: relative;
  width: 100%;
  margin: auto;
  margin-top: 60px;
}

.carousel-slide {
  display: flex;
  width: 100%;
  height: 400px;
}

.slide {
  flex: 1 0 100%;
  display: none;
  transition: opacity 1s ease;
}

.slide.active {
  display: block;
  opacity: 1;
}

.slide img {
  height: 400px;
  width: 100%;
}

.prev{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  font-size: 30px;
  font-weight: bold;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  cursor: pointer;
}

.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  font-size: 30px;
  font-weight: bold;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  cursor: pointer;
}

#about-img{
  
  height: 400px;
  border-radius: 15px;
}

.prev{
  left: 0;
}

.next {
  right: 0;
}

.footer-container{
  height: 100px;
  background-color: #b52974;
  margin-top: 100px;
  width: 100%;
}


/*-----------------------------------------Slideshow -----------------------------------------------css*/




@media screen and (max-width: 768px) {
  /* styles for screens smaller than 768px */

  .app-hindi{
    font-size: 22px;
  }

  .video-container{
    width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 700px;
      margin-top: -200px;
      margin-bottom: 124px;
  }

  *{
    width: fit-content;
  }
  
  #about-img{
    
    width: 537px;
    height: auto;
    border-radius: 15px;
  }

  body{
    width:auto;
  }

.title-box{
  position: relative;
  z-index: 100;
  color: white;
  width: 336px;
  height: 72px;
  padding: 30px;
  background-color: #b52974;
  border-radius: 15px;
  box-shadow: deepskyblue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  left: 5%;
  bottom: -81px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
}

.enjoy-box{
    position: relative;
    height: auto;
    background-color: #fefcdf;
    z-index: -1;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 40px;
    margin: 10px;
    width: 400px;
  }

  .opportunity-content{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    flex-direction: column;
  }

  .title-box{
    position: relative;
    z-index: 100;
    color: white;
    width: 261px;
    height: 79px;

    padding: 30px;
    background-color: #b52974;
    border-radius: 15px;
    box-shadow: deepskyblue;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
    left: -7%;
    bottom: -81px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
  }

  .enjoy-item{
    display: flex;
    justify-content: center;
    background-color: azure;
    width: 300px;
    margin: 30px;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
    height: auto;
  }

  .box{
    height: auto;
    background-color: #fefcdf;
    z-index: -1;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 40px;
    margin-top: 100px;
    margin: 10px;
    min-width: 100px;
    width: 500px;
  }


  .opportunity-item{
    display: flex;
    align-items: center;
    background-color: azure;
    width: 400px;
    height: auto;
    margin: 10px;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
  }


  .opportinity-title-box{
    position: relative;
    z-index: 100;
    width: 256px;
    height: 28px;

    padding: 42px;
    color: white;
    background-color: #b52974;
    border-radius: 15px;
    box-shadow: deepskyblue;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -10%;
    bottom: -55px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
  }


.enjoy-title-box{
  position: relative;
  z-index: 100;
  width: 209px;
  color: white;
  height: 28px;

  padding: 42px;
  background-color: #b52974;
  border-radius: 15px;
  box-shadow: deepskyblue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  left: -11%;
  bottom: -81px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
}

.slideshow {
  height: 350px;
  width: 102%;
}

.video-title-box{
  position: relative;
  z-index: 100;
  width: 170px;
  height: 28px;

  padding: 20px;
  background-color: #b52974;
  border-radius: 15px;
  box-shadow: deepskyblue;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  left: 5%;
  bottom: -100px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.5);
  color: white;
}

.videos{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 500px;
  align-items: center;
  margin-bottom: 100px;
  background-color: antiquewhite;
  width: 100%;
}

.start-now-container{
  height: 400px;
  margin-bottom: 250px;
}

.question{
  width: 500px;
}

iframe{
  width: 500px;
}

#about-img{
  width: 500px;
}


}